/************ Custom Fonts ************/
/************ CentraNo2 ************/
@font-face {
	font-family: Centra;
	src: url("./assets/font/CentraNo2/Bold.ttf");
	font-weight: 700;
}

@font-face {
	font-family: Centra;
	src: url("./assets/font/CentraNo2/Medium.ttf");
	font-weight: 500;
}

@font-face {
	font-family: Centra;
	src: url("./assets/font/CentraNo2/Regular.ttf");
	font-weight: 400;
}

/************ Chivo Mono ************/
@font-face {
	font-family: ChivoMono;
	src: url("./assets/font/Chivo_Mono/Bold.ttf");
	font-weight: 700;
}

@font-face {
	font-family: ChivoMono;
	src: url("./assets/font/Chivo_Mono/Medium.ttf");
	font-weight: 500;
}

@font-face {
	font-family: ChivoMono;
	src: url("./assets/font/Chivo_Mono/Regular.ttf");
	font-weight: 400;
}

/************ Default Css ************/
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	scroll-padding-top: 75px;
}

body {
	font-weight: 400;
	overflow-x: hidden;
	position: relative;
	background-color: #121212 !important;
	color: #fff !important;
	font-family: "Centra", sans-serif !important;
	text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	line-height: normal;
}

p,
a,
li,
button,
ul {
	margin: 0;
	padding: 0;
	line-height: normal;
	text-decoration: none;
}

#video {
	position: fixed;
	z-index: -1;
	width: 100%;
}

a:hover {
	text-decoration: none;
}

img {
	width: 100%;
	height: auto;
}

button {
	border: 0;
	background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
	outline: none;
}

@media (min-width: 1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

/************ Navbar Css ************/
nav.navbar {
	padding: 18px 0;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 9999;
	transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
	padding: 0px 0;
	background-color: #121212;
}

nav.navbar a.navbar-brand {
	width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
	font-family: "ChivoMono";
	font-weight: 400;
	color: #fff !important;
	letter-spacing: 0.8px;
	padding: 0 25px;
	font-size: 18px;
	opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
	opacity: 1;
}

span.navbar-text {
	display: flex;
	align-items: center;
}

.social-icon {
	display: inline-block;
	margin-left: 14px;
}

.social-icon a {
	width: 42px;
	height: 42px;
	background: rgba(217, 217, 217, 0.1);
	display: inline-flex;
	border-radius: 50%;
	margin-right: 6px;
	align-items: center;
	justify-content: center;
	line-height: 1;
	border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
	content: "";
	width: 42px;
	height: 42px;
	position: absolute;
	background-color: #ffffff;
	border-radius: 50%;
	transform: scale(0);
	transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
	transform: scale(1);
}

.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
	font-weight: 700;
	color: #fff;
	border: 1px solid #fff;
	padding: 18px 34px;
	font-size: 18px;
	margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}

.navbar-text button span {
	z-index: 1;
}

.navbar-text button::before {
	content: "";
	width: 0%;
	height: 100%;
	position: absolute;
	background-color: #fff;
	left: 0;
	top: 0;
	z-index: -1;
	transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
	color: #121212;
}

.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
	box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
	width: 24px;
	height: 17px;
	background-image: none;
	position: relative;
	border-bottom: 2px solid #fff;
	transition: all 300ms linear;
	top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: "";
	z-index: 2;
	transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/**
 * Popup
 */

.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	opacity: 0;
	animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    width: 80%;
    max-width: 500px;
}

.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
	margin: 5px 0;	
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

/************ Banner Css ************/
.banner {
	margin-top: 0;
	padding: 180px 0;
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
}

.banner .tagline {
	font-weight: 700;
	letter-spacing: 0.8px;
	padding: 8px 10px;
	background: linear-gradient(90.21deg,
			rgba(170, 54, 124, 0.5) -5.91%,
			rgba(74, 47, 189, 0.5) 111.58%);
	border: 1px solid rgba(255, 255, 255, 0.5);
	font-size: 20px;
	margin-bottom: 16px;
	display: inline-block;
}

.banner h1 {
	font-family: "ChivoMono";
	font-size: 65px;
	font-weight: 700;
	letter-spacing: 0.8px;
	line-height: 1;
	display: block;
}

.banner p {
	color: #b8b8b8;
	font-size: 18px;
	letter-spacing: 0.8px;
	line-height: 1.5em;
	width: 96%;
}

.banner button {
	color: #fff;
	font-weight: 700;
	font-size: 20px;
	margin-top: 60px;
	letter-spacing: 0.8px;
	display: flex;
	align-items: center;
}

.banner button svg {
	font-size: 25px;
	margin-left: 10px;
	transition: 0.3s ease-in-out;
	line-height: 1;
}

.banner button:hover svg {
	margin-left: 25px;
}

.banner img {
	animation: updown 3s linear infinite;
}

@keyframes updown {
	0% {
		transform: translateY(-20px);
	}

	50% {
		transform: translateY(20px);
	}

	100% {
		transform: translateY(-20px);
	}
}

.txt-rotate>.wrap {
	border-right: 0.08em solid #666;
}

.banner span {
	font-family: "ChivoMono";
	font-weight: 700;
	margin-bottom: 20px;
}

/************ Skills Css ************/
.skill {
	padding: 0 0 50px 0;
	position: relative;
}

.skill-bx {
	background: #151515;
	border-radius: 64px;
	text-align: center;
	padding: 60px 50px;
	margin-top: -60px;
}

.skill h2 {
	font-family: "ChivoMono";
	font-size: 45px;
	font-weight: 700;
}

.skill p {
	color: #b8b8b8;
	font-size: 18px;
	letter-spacing: 0.8px;
	line-height: 1.5em;
	margin: 14px 0 75px 0;
}

.skill-slider {
	width: 80%;
	margin: 0 auto;
	position: relative;
}

.skill-slider .item img {
	width: 50%;
	margin: 0 auto 15px auto;
}

.background-image-left {
	top: 28%;
	position: absolute;
	bottom: 0;
	width: 40%;
	z-index: -4;
}

/************ Projects Css ************/
.project {
	padding: 120px 0;
	position: relative;
	align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	top: 55%;
	width: 44px;
	height: 44px;
	margin-top: -22px;
	z-index: 10;
	cursor: pointer;
	display: block;
	background-size: 27px 44px;
	background-position: center;
	background-repeat: no-repeat;
	background-color: white;
	border: 1px solid #ccc;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease-in-out;
	border-radius: 100%;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
	background-color: #f0f0f0;
	box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.swiper-button-prev:active,
.swiper-button-next:active {
	background-color: #e0e0e0;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.swiper-button-prev {
    left: 10px;
	background-image: url('https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.8.1/icons/arrow-left.svg');
}

.swiper-button-next {
    right: 10px;
	background-image: url('https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.8.1/icons/arrow-right.svg');
}

.project h2 {
	font-family: "ChivoMono";
	font-size: 45px;
	font-weight: 700;
	text-align: center;
}

.project p {
	color: #b8b8b8;
	font-size: 18px;
	letter-spacing: 0.8px;
	line-height: 1.5em;
	margin: 14px auto 30px auto;
	text-align: center;
	width: 56%;
}

.project .nav.nav-pills {
	width: 72%;
	margin: 0 auto;
	border-radius: 50px;
	background-color: rgb(255 255 255 / 10%);
	overflow: hidden;
}

.project .nav.nav-pills .nav-item {
	width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
	background-color: transparent;
	border-radius: 0;
	padding: 17px 0;
	color: #fff;
	width: 100%;
	font-size: 17px;
	letter-spacing: 0.8px;
	font-weight: 500;
	position: relative;
	transition: 0.3s ease-in-out;
	text-align: center;
	z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
	content: "";
	position: absolute;
	width: 0;
	height: 100%;
	background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
	top: 0;
	left: 0;
	z-index: -1;
	transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
	width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
	border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 0 55px 55px 0;
}

.proj-imgbx {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 120px; /* Hauteur minimale pour les cartes */
    min-width: 100px; /* Largeur minimale pour les cartes */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.proj-imgbx::before {
	background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
	opacity: 1;
	position: absolute;
	width: 100%;
	transition: 0.4s ease-in-out;
	height: 0;
	border-radius: 10px;
	margin: 0;
	content: "";
}

.proj-imgbx:hover::before {
	height: 100%;
}

.proj-txtx {
	position: absolute;
	text-align: center;
	top: 65%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: 0.5s ease-in-out;
	opacity: 0;
	width: 100%;
}

.proj-imgbx:hover .proj-txtx {
	top: 40%;
	opacity: 1;
}

.proj-txtx h4 {
	font-size: 19px;
	color: #E8E8EF;
	font-weight: 700;
	letter-spacing: 0.8px;
}

.proj-txtx span {
	font-style: italic;
	color: #f45532;
	font-weight: 300;
	font-size: 10px;
	letter-spacing: 0.8px;
	text-align: center;
    margin-top: 10px;
}

.proj-imgbx:hover {
    transform: translateY(-1px);
	border-radius: 20px;
}

.background-image-right {
	top: 20%;
	position: absolute;
	bottom: 0;
	width: 35%;
	right: 0;
	z-index: -4;
}

.work-in-progress {
	margin-top: 2em;
	margin-bottom: 5em;
	text-align: center;
}

.work-in-progress img {
	height: 30%;
	width: 30%;
}

.proj-imgbx img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

/************ Contact Css ************/
.contact {
	background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
	padding: 60px 0 200px 0;
}

.contact img {
	width: 92%;
}

.contact h2 {
	font-size: 45px;
	font-weight: 700;
	margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
	width: 100%;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 20px;
	color: #fff;
	margin: 0 0 8px 0;
	padding: 18px 26px;
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 0.8px;
	transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
	background: rgba(255, 255, 255, 1);
	color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
	color: #121212;
	opacity: 0.8;
}

.contact form button {
	font-weight: 700;
	color: #000;
	background-color: #fff;
	padding: 14px 48px;
	font-size: 18px;
	margin-top: 25px;
	border-radius: 0;
	position: relative;
	transition: 0.3s ease-in-out;
}

.contact form button span {
	z-index: 1;
	position: relative;
}

.contact form button:hover {
	color: #fff;
}

.contact form button::before {
	content: "";
	background: #121212;
	width: 0;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
	width: 100%;
}

/************ Footer Css ************/
.footer {
	padding: 50px 0 50px 0;
	background-image: url("./assets/img/footer-bg.png");
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.newsletter-bx {
	background: #E8E8EF;
	border-radius: 55px;
	color: #121212;
	padding: 85px 125px;
	margin-bottom: 80px;
	margin-top: -122px;
}

.newsletter-bx h3 {
	font-weight: 700;
	letter-spacing: 0.5px;
	line-height: 1.2em;
}

.new-email-bx {
	background: #fff;
	padding: 5px;
	border-radius: 20px;
	position: relative;
	z-index: 0px;
	display: flex;
	align-items: center;
}

.new-email-bx::before {
	content: "";
	background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
	border-radius: 20px;
	position: absolute;
	z-index: -1;
	top: -1px;
	left: -1px;
	bottom: -1px;
	right: -1px;
}

.new-email-bx::after {
	content: "";
	background: #fff;
	border-radius: 20px;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.new-email-bx input {
	width: 100%;
	color: #121212;
	font-weight: 500;
	background: transparent;
	border: 0;
	padding: 0 15px;
}

.new-email-bx button {
	background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
	padding: 20px 65px;
	color: #fff;
	font-weight: 500;
	letter-spacing: 0.5px;
	border-radius: 19px;
}

.footer img {
	width: 26%;
}

.footer p {
	font-weight: 400;
	font-size: 14px;
	color: #b8b8b8;
	letter-spacing: 0.5px;
	margin-top: 20px;
}


form {
	margin-top: 20px;
	display: inline-block;
}

.formRow {
	margin: 10px 0 20px 0;
}

.formInput {
	padding: 15px 10px;
}

.blurred {
	filter: blur(10px);
	-webkit-filter: blur(10px);
	-moz-filter: blur(10px);
	-o-filter: blur(10px);
	-ms-filter: blur(10px);
	filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='10');
}

.errorMessage {
	color: #f45532;
	font-size: 14px;
	margin: 0;
	padding: 0;
}

.submit-btn {
	padding: 15px 50px;
	border-radius: 10px;
	margin-bottom: 25px;
}

@media (max-width: 768px) {
	.submit-message {
		width: 125px;
		margin-left: 200px;
	}
}